@tailwind base;
@tailwind components;
@tailwind utilities;
.bg-custom-image {
    background-image: url('https://landingfoliocom.imgix.net/store/collection/niftyui/images/hero-marketplace/3/background.png');
    background-size: cover;
    background-position: center;
    background-color: black;
}


.single1-image {
    background-image: url('./images/singleBack.jpeg');
    background-size: cover;
    background-position: center;
    background-color: black;
}

.single2-image {
    background-image: url('https://landingfoliocom.imgix.net/store/collection/niftyui/images/hero-marketplace/3/background.png');
    background-size: cover;
    background-position: center;
    background-color: black;
}

.single3-image {
    background-image: url('https://landingfoliocom.imgix.net/store/collection/niftyui/images/hero-marketplace/3/background.png');
    background-size: cover;
    background-position: center;
    background-color: black;
}

.single4-image {
    background-image: url('https://landingfoliocom.imgix.net/store/collection/niftyui/images/hero-marketplace/3/background.png');
    background-size: cover;
    background-position: center;
    background-color: black;
}